/* eslint-disable */
import * as Types from '../../../@generated/schema.d';

import { gql } from 'graphql.macro';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHoc from '@apollo/client/react/hoc';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FetchCompetitionsQueryVariables = Types.Exact<{
  year: Types.Scalars['String'];
}>;

export type FetchCompetitionsQuery = { readonly __typename?: 'Query' } & {
  readonly competitions?: Types.Maybe<
    { readonly __typename?: 'CompetitionsQueryResponse' } & Pick<
      Types.CompetitionsQueryResponse,
      'availableYears' | 'selectedYear'
    > & {
        readonly competitions?: Types.Maybe<
          ReadonlyArray<
            | ({ readonly __typename: 'AllSchoolsChampionships' } & Pick<
                Types.AllSchoolsChampionships,
                'id' | 'day' | 'agenda'
              >)
            | ({ readonly __typename: 'NoCompetition' } & Pick<Types.NoCompetition, 'id' | 'day' | 'agenda'>)
            | ({ readonly __typename: 'RockettRelays' } & Pick<
                Types.RockettRelays,
                'venueId' | 'program' | 'status' | 'results' | 'id' | 'day' | 'agenda'
              > & {
                  readonly venue?: Types.Maybe<
                    { readonly __typename?: 'Venue' } & Pick<Types.Venue, 'archived' | 'name' | 'street' | 'url'>
                  >;
                })
            | ({ readonly __typename: 'WeeklyCompetition' } & Pick<
                Types.WeeklyCompetition,
                'venueId' | 'program' | 'status' | 'results' | 'id' | 'day' | 'agenda'
              > & {
                  readonly venue?: Types.Maybe<
                    { readonly __typename?: 'Venue' } & Pick<Types.Venue, 'archived' | 'name' | 'street' | 'url'>
                  >;
                })
            | ({ readonly __typename: 'WinterChampionships' } & Pick<
                Types.WinterChampionships,
                'venueId' | 'program' | 'status' | 'results' | 'id' | 'day' | 'agenda'
              > & {
                  readonly venue?: Types.Maybe<
                    { readonly __typename?: 'Venue' } & Pick<Types.Venue, 'archived' | 'name' | 'street' | 'url'>
                  >;
                })
          >
        >;
      }
  >;
};

export type FetchCompetitionQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type FetchCompetitionQuery = { readonly __typename?: 'Query' } & {
  readonly competition?: Types.Maybe<
    | ({ readonly __typename?: 'AllSchoolsChampionships' } & Pick<
        Types.AllSchoolsChampionships,
        'id' | 'day' | 'agenda'
      >)
    | ({ readonly __typename?: 'NoCompetition' } & Pick<Types.NoCompetition, 'id' | 'day' | 'agenda'>)
    | ({ readonly __typename?: 'RockettRelays' } & Pick<
        Types.RockettRelays,
        'venueId' | 'program' | 'status' | 'id' | 'day' | 'agenda'
      > & {
          readonly venue?: Types.Maybe<{ readonly __typename?: 'Venue' } & Pick<Types.Venue, 'displayName' | 'url'>>;
          readonly fees?: Types.Maybe<
            { readonly __typename?: 'RockettRelaysFees' } & {
              readonly ONE_HOUR_RELAY: { readonly __typename?: 'RockettRelaysRelayFee' } & Pick<
                Types.RockettRelaysRelayFee,
                'weeklyCompetitor' | 'nonWeeklyCompetitor'
              >;
              readonly TWO_HOUR_RELAY: { readonly __typename?: 'RockettRelaysRelayFee' } & Pick<
                Types.RockettRelaysRelayFee,
                'weeklyCompetitor' | 'nonWeeklyCompetitor'
              >;
            }
          >;
          readonly rockettRelaysRegistrations?: Types.Maybe<
            { readonly __typename?: 'RockettRelaysRegistrationListData' } & {
              readonly data: ReadonlyArray<
                { readonly __typename?: 'RockettRelaysRegistration' } & Pick<Types.RockettRelaysRegistration, 'id'> & {
                    readonly competition?: Types.Maybe<
                      { readonly __typename?: 'RockettRelays' } & Pick<Types.RockettRelays, 'id' | 'day' | 'agenda'> & {
                          readonly venue?: Types.Maybe<
                            { readonly __typename?: 'Venue' } & Pick<Types.Venue, 'displayName'>
                          >;
                        }
                    >;
                    readonly teamDetails?: Types.Maybe<
                      { readonly __typename?: 'TeamDetails' } & Pick<
                        Types.TeamDetails,
                        'relay' | 'teamName' | 'ageGroup' | 'gender'
                      >
                    >;
                    readonly runnerDetails?: Types.Maybe<
                      ReadonlyArray<
                        { readonly __typename?: 'RockettRelaysRunnerDetails' } & Pick<
                          Types.RockettRelaysRunnerDetails,
                          'name' | 'position' | 'dateOfBirth' | 'gender'
                        >
                      >
                    >;
                  }
              >;
            }
          >;
        })
    | ({ readonly __typename?: 'WeeklyCompetition' } & Pick<
        Types.WeeklyCompetition,
        'status' | 'results' | 'program' | 'id' | 'day' | 'agenda'
      > & {
          readonly venue?: Types.Maybe<{ readonly __typename?: 'Venue' } & Pick<Types.Venue, 'name' | 'displayName'>>;
          readonly weeklyCompetitionRegistrations?: Types.Maybe<
            { readonly __typename?: 'WeeklyCompetitionRegistrationListData' } & {
              readonly data?: Types.Maybe<
                ReadonlyArray<
                  Types.Maybe<
                    { readonly __typename?: 'WeeklyCompetitionRegistration' } & Pick<
                      Types.WeeklyCompetitionRegistration,
                      | 'id'
                      | 'userAccountId'
                      | 'username'
                      | 'runnerDetailsCollectedDate'
                      | 'paymentCollectedDate'
                      | 'contactDetailsCollectedDate'
                    > & {
                        readonly contactDetails?: Types.Maybe<
                          { readonly __typename?: 'ContactDetails' } & Pick<
                            Types.ContactDetails,
                            'firstName' | 'lastName' | 'phoneNumber' | 'emailAddress'
                          >
                        >;
                        readonly runnerDetails?: Types.Maybe<
                          ReadonlyArray<
                            { readonly __typename?: 'WeeklyCompetitionRunnerDetails' } & Pick<
                              Types.WeeklyCompetitionRunnerDetails,
                              'firstName' | 'lastName' | 'dateOfBirth' | 'gender' | 'club' | 'bibNumber' | 'membership'
                            >
                          >
                        >;
                        readonly paymentDetails?: Types.Maybe<
                          { readonly __typename?: 'PaymentDetails' } & Pick<
                            Types.PaymentDetails,
                            'status' | 'price' | 'method'
                          >
                        >;
                      }
                  >
                >
              >;
            }
          >;
        })
    | ({ readonly __typename?: 'WinterChampionships' } & Pick<Types.WinterChampionships, 'id' | 'day' | 'agenda'>)
  >;
};

export const FetchCompetitionsDocument = gql`
  query fetchCompetitions($year: String!) {
    competitions(year: $year) {
      availableYears
      selectedYear
      competitions {
        __typename
        id
        day
        agenda
        ... on WeeklyCompetition {
          venueId
          venue {
            archived
            name
            street
            url
          }
          program
          status
          results
        }
        ... on RockettRelays {
          venueId
          venue {
            archived
            name
            street
            url
          }
          program
          status
          results
        }
        ... on WinterChampionships {
          venueId
          venue {
            archived
            name
            street
            url
          }
          program
          status
          results
        }
      }
    }
  }
`;
export type FetchCompetitionsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<FetchCompetitionsQuery, FetchCompetitionsQueryVariables>,
  'query'
> &
  ({ variables: FetchCompetitionsQueryVariables; skip?: boolean } | { skip: boolean });

export const FetchCompetitionsComponent = (props: FetchCompetitionsComponentProps) => (
  <ApolloReactComponents.Query<FetchCompetitionsQuery, FetchCompetitionsQueryVariables>
    query={FetchCompetitionsDocument}
    {...props}
  />
);

export type FetchCompetitionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<FetchCompetitionsQuery, FetchCompetitionsQueryVariables>;
} &
  TChildProps;
export function withFetchCompetitions<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FetchCompetitionsQuery,
    FetchCompetitionsQueryVariables,
    FetchCompetitionsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FetchCompetitionsQuery,
    FetchCompetitionsQueryVariables,
    FetchCompetitionsProps<TChildProps, TDataName>
  >(FetchCompetitionsDocument, {
    alias: 'fetchCompetitions',
    ...operationOptions,
  });
}

/**
 * __useFetchCompetitionsQuery__
 *
 * To run a query within a React component, call `useFetchCompetitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCompetitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCompetitionsQuery({
 *   variables: {
 *      year: // value for 'year'
 *   },
 * });
 */
export function useFetchCompetitionsQuery(
  baseOptions: Apollo.QueryHookOptions<FetchCompetitionsQuery, FetchCompetitionsQueryVariables>,
) {
  return Apollo.useQuery<FetchCompetitionsQuery, FetchCompetitionsQueryVariables>(
    FetchCompetitionsDocument,
    baseOptions,
  );
}
export function useFetchCompetitionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchCompetitionsQuery, FetchCompetitionsQueryVariables>,
) {
  return Apollo.useLazyQuery<FetchCompetitionsQuery, FetchCompetitionsQueryVariables>(
    FetchCompetitionsDocument,
    baseOptions,
  );
}
export type FetchCompetitionsQueryHookResult = ReturnType<typeof useFetchCompetitionsQuery>;
export type FetchCompetitionsLazyQueryHookResult = ReturnType<typeof useFetchCompetitionsLazyQuery>;
export type FetchCompetitionsQueryResult = Apollo.QueryResult<FetchCompetitionsQuery, FetchCompetitionsQueryVariables>;
export const FetchCompetitionDocument = gql`
  query fetchCompetition($id: String!) {
    competition(id: $id) {
      id
      day
      agenda
      ... on RockettRelays {
        venueId
        venue {
          displayName
          url
        }
        program
        status
        fees {
          ONE_HOUR_RELAY {
            weeklyCompetitor
            nonWeeklyCompetitor
          }
          TWO_HOUR_RELAY {
            weeklyCompetitor
            nonWeeklyCompetitor
          }
        }
        rockettRelaysRegistrations {
          data {
            id
            competition {
              id
              day
              agenda
              venue {
                displayName
              }
            }
            teamDetails {
              relay
              teamName
              ageGroup
              gender
            }
            runnerDetails {
              name
              position
              dateOfBirth
              gender
            }
          }
        }
      }
      ... on WeeklyCompetition {
        status
        results
        program
        venue {
          name
          displayName
        }
        weeklyCompetitionRegistrations {
          data {
            id
            userAccountId
            username
            contactDetails {
              firstName
              lastName
              phoneNumber
              emailAddress
            }
            runnerDetails {
              firstName
              lastName
              dateOfBirth
              gender
              club
              bibNumber
              membership
            }
            paymentDetails {
              status
              price
              method
            }
            runnerDetailsCollectedDate
            paymentCollectedDate
            contactDetailsCollectedDate
          }
        }
      }
    }
  }
`;
export type FetchCompetitionComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<FetchCompetitionQuery, FetchCompetitionQueryVariables>,
  'query'
> &
  ({ variables: FetchCompetitionQueryVariables; skip?: boolean } | { skip: boolean });

export const FetchCompetitionComponent = (props: FetchCompetitionComponentProps) => (
  <ApolloReactComponents.Query<FetchCompetitionQuery, FetchCompetitionQueryVariables>
    query={FetchCompetitionDocument}
    {...props}
  />
);

export type FetchCompetitionProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<FetchCompetitionQuery, FetchCompetitionQueryVariables>;
} &
  TChildProps;
export function withFetchCompetition<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FetchCompetitionQuery,
    FetchCompetitionQueryVariables,
    FetchCompetitionProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FetchCompetitionQuery,
    FetchCompetitionQueryVariables,
    FetchCompetitionProps<TChildProps, TDataName>
  >(FetchCompetitionDocument, {
    alias: 'fetchCompetition',
    ...operationOptions,
  });
}

/**
 * __useFetchCompetitionQuery__
 *
 * To run a query within a React component, call `useFetchCompetitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCompetitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCompetitionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchCompetitionQuery(
  baseOptions: Apollo.QueryHookOptions<FetchCompetitionQuery, FetchCompetitionQueryVariables>,
) {
  return Apollo.useQuery<FetchCompetitionQuery, FetchCompetitionQueryVariables>(FetchCompetitionDocument, baseOptions);
}
export function useFetchCompetitionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchCompetitionQuery, FetchCompetitionQueryVariables>,
) {
  return Apollo.useLazyQuery<FetchCompetitionQuery, FetchCompetitionQueryVariables>(
    FetchCompetitionDocument,
    baseOptions,
  );
}
export type FetchCompetitionQueryHookResult = ReturnType<typeof useFetchCompetitionQuery>;
export type FetchCompetitionLazyQueryHookResult = ReturnType<typeof useFetchCompetitionLazyQuery>;
export type FetchCompetitionQueryResult = Apollo.QueryResult<FetchCompetitionQuery, FetchCompetitionQueryVariables>;
