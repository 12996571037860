import React, { Fragment, useContext } from 'react';
import UserContext from '../../UserContext';

const Profile = () => {
  const { user } = useContext(UserContext);

  if (!user) {
    return null;
  }

  return <Fragment>{/*<img src={user.picture} alt="Profile" height='20px' width='auto'/>*/}</Fragment>;
};

export default Profile;
