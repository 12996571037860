// @ts-ignore

import React, { Component } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import UserContext from './UserContext';
import { App } from './App';
import { isEmpty } from 'lodash/fp';
import {ROLE} from "./@utils/role";
import { HubCapsule } from '@aws-amplify/core';

type AdministratorUser = CognitoUser & {
  roles?: ROLE[];
}

class AuthApp extends Component {
  state = {
    currentUser: undefined,
    isLoaded: false,
  };
  componentDidMount() {
    //@ts-ignore
    this.updateCurrentUser();

    Hub.listen('auth', (data: HubCapsule) => {
      switch (data.payload.event) {
        case 'signIn':
          this.updateCurrentUser(data.payload.data)
          break;
        case 'signUp':
          // console.log('user signed up');
          break;
        case 'signOut':
          this.setState({ currentUser: null, isLoaded: true });
          break;
        case 'signIn_failure':
          // console.log('user sign in failed');
          break;
        case 'configured':
          // console.log('the Auth module is configured');
      }
    });
  }

  // @ts-ignore
  updateCurrentUser = async (user: CognitoUser) => {
    if (user) {
      const adminUser: AdministratorUser = user;
      // @ts-ignore
      adminUser.roles = user.getSignInUserSession().getIdToken().payload['cognito:groups'];
      this.setState({ currentUser: adminUser, isLoaded: true });
      return;
    }
    try {
      const user = await Auth.currentAuthenticatedUser();
      const adminUser: AdministratorUser = user;
      const roles = user.getSignInUserSession().getIdToken().payload['cognito:groups'];
      adminUser.roles = roles || [];

      this.setState({ currentUser: adminUser, isLoaded: true });
    } catch (err) {
      this.setState({ currentUser: null, isLoaded: true });
    }
  };
  render() {
    return (
      <UserContext.Provider
        value={{
          user: isEmpty(this.state.currentUser) ? undefined : this.state.currentUser,
          // @ts-ignore
          updateCurrentUser: this.updateCurrentUser,
          isLoaded: this.state.isLoaded,
        }}>
        <App />
      </UserContext.Provider>
    );
  }
}

export default AuthApp;
