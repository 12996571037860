import { useState } from 'react';
import { useFetchCompetitionsQuery } from '@containers/pages/event/queries.generated';
import { useConfig } from '@constants/config';

const useCalendar = () => {
  const {
    constants: { currentYear },
  } = useConfig();
  const [year, setYear] = useState<string>(currentYear);
  const { data, loading, error } = useFetchCompetitionsQuery({
    variables: {
      year
    },
  });

  // @ts-ignore
  const availableYears = (['2025', '2024', '2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016']).map((year: string): any => ({
  // const availableYears = (data?.competitions?.availableYears || []).map((year: string): any => ({
    key: year,
    text: year,
    value: year,
    onClick: () => setYear(year),
  }));

  return {
    loading,
    error,
    setYear,
    calendar: {
      events: data?.competitions?.competitions ?? [],
    },
    availableYears,
    currentYear: year,
  };
};

export { useCalendar };
